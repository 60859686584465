import React from "react";
import Layout from "../layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import "normalize.css/normalize.css";
import "../styles/reset.scss";
import "./photographs.scss";

const Photographs = () => {
  const data = useStaticQuery(graphql`
    query GalleryContent {
      contentfulPhotoGallery {
        heading {
          heading
        }
        images {
          gatsbyImageData
        }
      }
    }
  `);

  // Comes direct from contentful data.
  const content = data.contentfulPhotoGallery;

  const displayGallery = content.images.map((element, index) => {
    return (
      <div className="image-container">
        <GatsbyImage
          className="gallery-image"
          key={index}
          image={element.gatsbyImageData}
          alt="An image that was taken on a olympus camera"
          objectFit="cover"
        ></GatsbyImage>
      </div>
    );
  });

  return (
    <Layout>
      <div className="heading-container">
        <header>
          <h1>{content.heading.heading}</h1>
        </header>
      </div>
      <motion.div
        className="gallery-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        {displayGallery}
      </motion.div>
    </Layout>
  );
};

export default Photographs;
